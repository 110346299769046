<template>
  <div class="buyListing">
    <div class="tab-top">
      <div
        class="item"
        @click="qiehuan(i)"
        :class="i == index ? 'active' : ''"
        v-for="(item, i) in listData"
        :key="i"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="ipt">
      <i style="font-size: 20px; color: #b0b0b0" class="el-icon-search"></i>
      <input
        @input="getData()"
        placeholder="通过订单编号或者商品名称在所有订单内搜索"
        type="text"
        v-model="keywords"
      />
    </div>
    <div class="list" v-if="orderList.list && orderList.list.length != 0">
      <div v-for="item in orderList.list" :key="item.id">
        <div class="item">
          <div style="padding: 0 20px; background: #fff">
            <div class="shopName">
              <div
                style="
                  font-weight: 600;
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                "
              >
                {{ item.shop_name }}
                <el-button
                  @click.stop="toStore(item)"
                  icon="el-icon-burger"
                  style="margin-left: 10px"
                  size="mini"
                  plain
                  >查看商店</el-button
                >
                <!-- <el-button
                  @click.stop="toStore(item)"
                  icon="el-icon-chat-line-round"
                  style="margin-left: 10px"
                  size="mini"
                  plain
                  >即时聊天</el-button
                > -->
              </div>
              <div style="color: #ee4d2d">{{ state[item.state] }}</div>
            </div>
          </div>
          <div
            @click="toInfo(item)"
            class="goods-list"
            v-for="val in item.orderGoods"
            :key="val.id"
          >
            <div>
              <el-image
                style="width: 80px; height: 80px"
                :src="val.image"
                fit="fit"
              ></el-image>
            </div>
            <div style="padding: 0 20px; width: 80%">
              <div style="height: 50px">
                {{ val.title }}
                {{ val.difference ? ",[" + val.difference + "]" : "" }}
              </div>
              <div>x{{ val.number }}</div>
            </div>
            <div
              v-if="
                parseFloat(val.coupon_discount_price) +
                  parseFloat(val.suit_discount_price) +
                  parseFloat(val.discount_price) ==
                '你好'
              "
              style="
                width: 20%;
                line-height: 80px;
                text-align: center;
                color: #ee4d2d;
              "
            >
              RM{{ val.price }}
              <!-- {{val.coupon_price+','+ val.discount_price+','+ val.suit_price}} -->
            </div>
            <div
              v-if="1 === 0"
              style="
                width: 20%;
                line-height: 80px;
                text-align: center;
                color: #ee4d2d;
              "
            >
              <span
                style="
                  color: #b0b0b0;
                  font-size: 12px;
                  text-decoration: line-through;
                "
                >RM{{ val.price }}</span
              >
              <!-- RM{{
                (
                  parseFloat(val.price * val.number) -
                  (parseFloat(val.coupon_discount_price) +
                    parseFloat(val.suit_discount_price) +
                    parseFloat(val.discount_price))
                ).toFixed(2)

              }} -->
              RM{{ (parseFloat(val.actual_payment) / val.number).toFixed(2) }}

              <!-- {{val.coupon_price+','+ val.discount_price+','+ val.suit_price}} -->
            </div>
            <div
              style="
                width: 20%;
                line-height: 80px;
                text-align: right;
                color: #ee4d2d;
              "
            >
              RM{{ val.price }}
              <!-- {{val.coupon_price+','+ val.discount_price+','+ val.suit_price}} -->
            </div>
          </div>
        </div>
        <div class="bottom">
          <div
            style="
              display: flex;
              justify-content: right;
              align-items: center;
              background: #fffefb;
            "
          >
            订单总额：<span style="font-size: 24px; color: #ee4d2d"
              >RM{{
                (
                  parseFloat(item.total_price) -
                  (parseFloat(item.coupon_price) +
                    parseFloat(item.discount_price) +
                    parseFloat(item.suit_price))
                ).toFixed(2)
              }}</span
            >
          </div>
          <div
            v-if="item.state == 1"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button @click="handlePay(item)" size="medium" plain
              >确认付款</el-button
            >
            <el-button @click.stop="handleCancel(item.id)" size="medium" plain
              >取消订单</el-button
            >
          </div>
          <div
            v-if="item.state == 2"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button disabled size="medium" type="info" plain
              >等待出货</el-button
            >
          </div>
          <div
            v-if="item.state == 3"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button disabled size="medium" type="info" plain
              >等待发货</el-button
            >
          </div>
          <div
            v-if="item.state == 4"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button
              v-if="item.pay_type == 1"
              @click="handleRef(item.id)"
              size="medium"
              plain
              >退货退款</el-button
            >
            <el-button size="medium" plain @click="handleConfirm(item)"
              >确认收货</el-button
            >
          </div>
          <div
            v-if="item.state == 5"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button @click="toInfo(item)" size="medium" plain
              >查看详情</el-button
            >
            <el-button
              @click="handleCommen(item.id)"
              v-if="item.evaluate_state == 0"
              size="medium"
              plain
              >写评价</el-button
            >
          </div>
          <div
            v-if="item.state == 6"
            style="display: flex; justify-content: right; margin-top: 20px"
          >
            <el-button
              @click="handleRepeatBuy(item)"
              style="min-width: 160px"
              size="small"
              type="primary"
              >再买一次</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      style="
        line-height: 40px;
        font-size: 14px;
        color: #b0b0b0;
        text-align: center;
      "
    >
      暂无
    </div>
    <div style="padding-top: 20px; text-align: right">
      <el-pagination
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="orderList.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { purchaseList, payment, confirmOrder } from "@/api/buy.js";
import { addBuyCart } from "@/api/home.js";
export default {
  data() {
    return {
      listData: [
        { name: "全部" },
        { name: "待付款" },
        { name: "待出货" },
        { name: "待收货" },
        { name: "已完成" },
        { name: "取消" },
      ],
      index: 0,
      orderList: "",
      state: [
        "全部",
        "待付款",
        "待出货",
        "待发货",
        "待收货",
        "已完成",
        "已取消",
        "退货/退款",
        "待评论",
      ],
      keywords: "",
      page: 1,
      pageSize: 5,
    };
  },
  created() {
    this.$store.state.buyMenuIndex = 2;
    this.getData();
  },
  methods: {
    getData() {
      purchaseList({
        keywords: this.keywords,
        status: this.index,
        page: this.page,
        page_size: this.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.orderList = res.data;
        }
      });
    },
    qiehuan(index) {
      this.index = index;
      this.getData();
    },
    toStore(item) {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: item.shop_id,
          // addTime: this.infoData.shop.join_time,
          // onlineTime: this.infoData.shop.online_time,
        },
      });
    },
    handlePay(item) {
      payment({
        order_id: item.id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.getData();
        }
      });
    },
    handleCancel(id) {
      sessionStorage.ORDERID = id;
      sessionStorage.OPER = "cancel";
      this.$router.push({ path: "buyOrderInfo" });
    },
    toInfo(value) {
      if (value.state == 7) {
        return false;
      }
      // if (value.state == 7) {
      //   sessionStorage.ORDERID = value.id;
      //   this.$router.push({ path: "buyOrderRef" });
      //   return false;
      // }
      sessionStorage.ORDERID = value.id;
      this.$router.push("buyOrderInfo");
    },
    // 确认收货
    handleConfirm(val) {
      confirmOrder({
        id: val.id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("确认收货成功");
          this.getData();
        }
      });
    },
    handleCommen(id) {
      sessionStorage.ORDERID = id;
      sessionStorage.OPER = "comment";
      this.$router.push({ path: "buyOrderInfo" });
    },
    handleRef(id) {
      sessionStorage.ORDERID = id;
      this.$router.push({ path: "buyOrderRef" });
    },
    pageChange(index) {
      this.page = index;
      this.getData();
    },
    handleRepeatBuy(value) {
      let arr = [];
      value.orderGoods.forEach((item) => {
        arr.push({
          goods_id: item.goods_id,
          num: item.number,
          sku_id: item.goods_sku_id,
        });
      });
      addBuyCart({ lists: arr }).then((res) => {
        if (res.code == 1) {
          this.$router.push("buyCart");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.buyListing {
  padding: 20px;
  .tab-top {
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    .item {
      text-align: center;
      width: 16.66%;
      //   padding: 10px;
      height: 54px;
      line-height: 54px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid #ee4d2d;
      color: #ee4d2d;
    }
  }
  .ipt {
    width: 100%;
    height: 46px;
    background: #eaeaea;
    padding: 0 20px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      height: 46px;
      margin-left: 10px;
      background: transparent;
      border: none;
    }
    input::-webkit-input-placeholder {
      color: #b0b0b0;
    }
  }
  .list {
    font-size: 14px;

    border-radius: 6px;
    .item {
      background: #fff;
      margin-top: 10px;

      // padding: 20px;
      .shopName {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        // margin: 0 20px 0;
        padding: 20px 0 10px;
        align-items: center;
      }
      .goods-list {
        display: flex;
        padding: 10px 20px;
        border-bottom: 1px dashed #eee;
        cursor: pointer;
      }
    }
  }
  .bottom {
    background: #fffefb;
    padding: 30px 0;
    padding-right: 20px;
    border-radius: 6px;
  }
}
</style>